import { SVGProps } from 'react';

function Mastercard({ height = 24, width = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_257_12138)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
          fill="#211E1E"
        />
        <rect x="1" y="4" width="22" height="16" rx="2" fill="#201D1C" />
        <rect
          x="0.5"
          y="3.5"
          width="23"
          height="17"
          rx="2.5"
          stroke="black"
          strokeOpacity="0.1"
        />
        <rect
          width="16"
          height="16"
          transform="translate(4 4)"
          fill="#201D1C"
        />
        <path
          d="M14.1634 8.11938H9.83691V15.8942H14.1634V8.11938Z"
          fill="#FF5F00"
        />
        <path
          d="M10.1116 12.007C10.1109 11.2583 10.2806 10.5192 10.6078 9.84566C10.935 9.17216 11.4111 8.58192 12.0001 8.11964C11.2707 7.5463 10.3947 7.18975 9.47219 7.09074C8.54971 6.99173 7.61799 7.15426 6.78352 7.55974C5.94904 7.96523 5.24548 8.59731 4.75325 9.38375C4.26103 10.1702 4 11.0792 4 12.007C4 12.9348 4.26103 13.8439 4.75325 14.6303C5.24548 15.4167 5.94904 16.0488 6.78352 16.4543C7.61799 16.8598 8.54971 17.0223 9.47219 16.9233C10.3947 16.8243 11.2707 16.4678 12.0001 15.8944C11.4111 15.4321 10.935 14.8419 10.6078 14.1684C10.2806 13.4949 10.1109 12.7558 10.1116 12.007Z"
          fill="#EB001B"
        />
        <path
          d="M20 12.007C20 12.9348 19.739 13.8438 19.2468 14.6303C18.7546 15.4167 18.0511 16.0488 17.2166 16.4543C16.3822 16.8598 15.4504 17.0223 14.528 16.9233C13.6055 16.8243 12.7295 16.4677 12.0001 15.8944C12.5886 15.4317 13.0644 14.8413 13.3915 14.1679C13.7187 13.4945 13.8886 12.7557 13.8886 12.007C13.8886 11.2584 13.7187 10.5195 13.3915 9.84611C13.0644 9.17272 12.5886 8.58239 12.0001 8.11964C12.7295 7.5463 13.6055 7.18975 14.528 7.09074C15.4504 6.99173 16.3822 7.15426 17.2166 7.55975C18.0511 7.96524 18.7546 8.59733 19.2468 9.38377C19.739 10.1702 20 11.0793 20 12.007Z"
          fill="#F79E1B"
        />
        <path
          d="M19.5282 15.0708V14.9116H19.5924V14.8792H19.429V14.9116H19.4932V15.0708H19.5282ZM19.8456 15.0708V14.8788H19.7955L19.7378 15.0109L19.6802 14.8788H19.6301V15.0708H19.6654V14.926L19.7195 15.0508H19.7562L19.8102 14.9257V15.0708H19.8456Z"
          fill="#F79E1B"
        />
      </g>
      <defs>
        <clipPath id="clip0_257_12138">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Mastercard.displayName = 'Mastercard';

export default Mastercard;
