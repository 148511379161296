import { useIntl } from 'react-intl';

import * as S from './Footer.styles';

import { Spacer, Text } from '@ui';
import { FlexContainer } from '@src/components/shared';

function Footer() {
  const intl = useIntl();
  return (
    <footer>
      <Text color="light" colorVariant="secondary" align="center" small>
        © Higo Inc 2023
      </Text>
      <Spacer size={2} />
      <FlexContainer justifyContent="center">
        <S.Link
          href="https://www.usesammy.com/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text color="light" colorVariant="secondary" small>
            {intl.formatMessage({
              defaultMessage: 'Sammy Privacy Policy',
              id: 'sd4xoa',
            })}
          </Text>
        </S.Link>

        <Spacer size={2} horizontal />
        <Text color="light" colorVariant="secondary" small>
          |
        </Text>
        <Spacer size={2} horizontal />

        <S.Link
          href="https://www.usesammy.com/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Text color="light" colorVariant="secondary" small>
            {intl.formatMessage({
              defaultMessage: 'Terms of Service',
              id: '32rBNK',
            })}
          </Text>
        </S.Link>
      </FlexContainer>
    </footer>
  );
}

export default Footer;
