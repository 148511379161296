import styled, { css } from 'styled-components';

import { BoxProps } from './types';

const Box = styled.div<BoxProps>`
  padding: ${({
    theme,
    padding = 0,
    paddingLeft = 0,
    paddingRight = 0,
    paddingHorizontal = 0,
    paddingVertical = 0,
    paddingBottom = 0,
    paddingTop = 0,
  }) => {
    const isCustomPadding =
      paddingLeft ||
      paddingHorizontal ||
      paddingRight ||
      paddingVertical ||
      paddingBottom ||
      paddingTop;
    const paddingL = isCustomPadding
      ? paddingLeft || paddingHorizontal || padding
      : padding;
    const paddingR = isCustomPadding
      ? paddingRight || paddingHorizontal || padding
      : padding;
    const paddingT = isCustomPadding
      ? paddingTop || paddingVertical || padding
      : padding;
    const paddingB = isCustomPadding
      ? paddingBottom || paddingVertical || padding
      : padding;
    return `${theme.spacing[paddingT]}px ${theme.spacing[paddingR]}px ${theme.spacing[paddingB]}px ${theme.spacing[paddingL]}px`;
  }};

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export default Box;
