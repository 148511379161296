import { useTheme } from 'styled-components';

import * as library from './Outline';
import { IconProps } from './types';

function Icon({
  name,
  color = 'light',
  colorVariant = 'primary',
  size = 24,
  ...otherProps
}: IconProps) {
  const theme = useTheme();
  const SVGIcon = library[name];

  if (size > 32) {
    throw new Error(`Icons do not support sizes greater than 32px`);
  }

  return (
    <SVGIcon
      {...otherProps}
      width={size}
      height={size}
      color={theme.colors.content[color][colorVariant]}
      strokeWidth={size > 16 ? 2 : 1}
    />
  );
}

export default Icon;
