import styled, { css } from 'styled-components';

import UIPlaceholder from '../Placeholder';
import { CircleIconProps } from './types';

const extraSmallStyles = css`
  width: ${({ theme }) => theme.spacing[6]}px;
  height: ${({ theme }) => theme.spacing[6]}px;
  border-radius: ${({ theme }) => theme.spacing[3]}px;
`;

const smallStyles = css`
  width: ${({ theme }) => theme.spacing[10]}px;
  height: ${({ theme }) => theme.spacing[10]}px;
  border-radius: ${({ theme }) => theme.spacing[5]}px;
`;

const mediumStyles = css`
  width: ${({ theme }) => theme.spacing[12]}px;
  height: ${({ theme }) => theme.spacing[12]}px;
  border-radius: ${({ theme }) => theme.spacing[6]}px;
`;

const largeStyles = css`
  width: ${({ theme }) => theme.spacing[16]}px;
  height: ${({ theme }) => theme.spacing[16]}px;
  border-radius: ${({ theme }) => theme.spacing[8]}px;
`;

const extraLargeStyles = css`
  width: ${({ theme }) => theme.spacing[18]}px;
  height: ${({ theme }) => theme.spacing[18]}px;
  border-radius: ${({ theme }) => theme.spacing[9]}px;
`;

export const Container = styled.div<Omit<CircleIconProps, 'icon'>>`
  background-color: ${({
    backgroundColor = 'light',
    backgroundColorVariant = 'primary',
    theme,
  }) => theme.colors.surface[backgroundColor][backgroundColorVariant]};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ borderStyle }) =>
    typeof borderStyle !== 'undefined' &&
    css<Omit<CircleIconProps, 'icon'>>`
      border-width: 1px;
      border-style: ${borderStyle};
      border-color: ${({ theme, borderColor, borderColorVariant }) =>
        borderColor &&
        borderColorVariant &&
        theme.colors.content[borderColor][borderColorVariant]};
    `};

  ${({ size }) => size === 'xs' && extraSmallStyles}
  ${({ size }) => size === 'sm' && smallStyles}
  ${({ size }) => size === 'md' && mediumStyles}
  ${({ size }) => size === 'lg' && largeStyles}
  ${({ size }) => size === 'xl' && extraLargeStyles}
`;

interface PlaceholderProps {
  $size: CircleIconProps['size'];
}

export const Placeholder = styled(UIPlaceholder)<PlaceholderProps>`
  ${({ $size }) => $size === 'xs' && extraSmallStyles}
  ${({ $size }) => $size === 'sm' && smallStyles}
  ${({ $size }) => $size === 'md' && mediumStyles}
  ${({ $size }) => $size === 'lg' && largeStyles}
  ${({ $size }) => $size === 'xl' && extraLargeStyles}
`;
