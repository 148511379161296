import styled, { css } from 'styled-components';

interface PressableProps {
  size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const Button = styled.button<PressableProps>`
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  &:active {
    transform: scale(0.99);
  }
  ${({ size }) =>
    size === 'xs' &&
    css`
      width: ${({ theme }) => theme.spacing[6]}px;
      height: ${({ theme }) => theme.spacing[6]}px;
      border-radius: ${({ theme }) => theme.spacing[3]}px;
    `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      width: ${({ theme }) => theme.spacing[10]}px;
      height: ${({ theme }) => theme.spacing[10]}px;
      border-radius: ${({ theme }) => theme.spacing[5]}px;
    `}

  ${({ size }) =>
    size === 'md' &&
    css`
      width: ${({ theme }) => theme.spacing[12]}px;
      height: ${({ theme }) => theme.spacing[12]}px;
      border-radius: ${({ theme }) => theme.spacing[6]}px;
    `}
      
      ${({ size }) =>
    size === 'lg' &&
    css`
      width: ${({ theme }) => theme.spacing[16]}px;
      height: ${({ theme }) => theme.spacing[16]}px;
      border-radius: ${({ theme }) => theme.spacing[8]}px;
    `}
      
      ${({ size }) =>
    size === 'xl' &&
    css`
      width: ${({ theme }) => theme.spacing[18]}px;
      height: ${({ theme }) => theme.spacing[18]}px;
      border-radius: ${({ theme }) => theme.spacing[9]}px;
    `}
`;
