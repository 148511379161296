import { SVGProps } from 'react';

function ArrowClockwise({
  height = 24,
  width = 24,
  color = '#000',
  strokeWidth = 2,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.516 9.348h4.5v-4.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.834 17.834a8.25 8.25 0 1 1 0-11.668l3.182 3.182"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

ArrowClockwise.displayName = 'ArrowClockwise';

export default ArrowClockwise;
