import styled from 'styled-components';

import Text from '../../ui/Text';

const Balance = styled(Text)`
  font-size: ${({ theme }) => theme.spacing[12]}px;
  line-height: ${({ theme }) => theme.spacing[14]}px;
  font-family: ${({ theme }) => theme.fontFamily.sans};
  letter-spacing: -${({ theme }) => theme.spacing[0.5]}px;
`;

export default Balance;
