export { default as Amex } from './Amex';
export { default as ArrowClockwise } from './ArrowClockwise';
export { default as Check } from './Check';
export { default as CheckCircle } from './CheckCircle';
export { default as Clock } from './Clock';
export { default as Discover } from './Discover';
export { default as Mastercard } from './Mastercard';
export { default as MinusCircle } from './MinusCircle';
export { default as Sammy } from './Sammy';
export { default as ShieldCheck } from './ShieldCheck';
export { default as ShieldChevron } from './ShieldChevron';
export { default as SmileySad } from './SmileySad';
export { default as Visa } from './Visa';
export { default as X } from './X';
