import styled from 'styled-components';

import { FlexContainer } from '@src/components/shared';

export const Container = styled(FlexContainer)`
  height: ${({ theme }) => theme.spacing[28]}px;
  display: flex;
  align-items: center;
  margin: auto;

  @media (max-width: 576px) {
    padding: 0 ${({ theme }) => theme.spacing[6]}px;
  }

  /* Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    max-width: ${({ theme }) => theme.spacing[128]}px;
  }
`;

export const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  height: ${({ theme }) => theme.spacing[10]}px;
  border-radius: ${({ theme }) => theme.spacing[5]}px;
  padding: ${({ theme }) => `0 ${theme.spacing[4]}px`};
  text-decoration: none;
  background-color: ${({ theme }) => theme.colors.surface['dark']['secondary']};
  outline: none;
`;
