import { useTheme } from 'styled-components';

import * as S from './Button.styles';

import { LoadingIcon } from '@src/components/shared';

import { ButtonProps } from './types';

export default function Button({
  children,
  disabled,
  loading,
  size,
  color = 'color',
  colorVariant = 'primary',
  variant = 'solid',
  ...otherProps
}: ButtonProps) {
  const theme = useTheme();

  const getTextColor = () => {
    if (color === 'transparent') {
      return theme.colors.content.light.secondary;
    }

    if (variant === 'outlined') {
      return theme.colors.content.light.primary;
    }
    return theme.colors.content[color][colorVariant];
  };

  const loadingColor = getTextColor();

  return (
    <S.Button
      color={color}
      colorVariant={colorVariant}
      variant={variant}
      disabled={disabled || loading}
      size={size}
      {...otherProps}
    >
      {loading ? (
        <LoadingIcon size="sm" color={loadingColor} />
      ) : (
        <S.Label
          color={color}
          colorVariant={colorVariant}
          variant={variant}
          size={size}
        >
          {children}
        </S.Label>
      )}
    </S.Button>
  );
}
