import { Spacing } from 'styled-components';
import styled, { css } from 'styled-components';

import Placeholder from '../Placeholder';
import { TextProps } from './types';

interface TextPlaceholderProps {
  width?: Spacing;
  variant?: TextProps['variant'];
  small?: TextProps['small'];
  height?: Spacing;
}

const TextPlaceholder = styled(Placeholder)<TextPlaceholderProps>`
  height: ${({ theme, small, height }) =>
    small
      ? theme.spacing[3]
      : height
      ? theme.spacing[height]
      : theme.spacing[4]}px;
  width: ${({ theme, width }) =>
    width ? `${theme.spacing[width]}px` : '100%'} !important;
  border-radius: ${({ theme, small }) =>
    small ? theme.spacing[1.5] : theme.spacing[2]}px;
  margin: ${({ theme }) => theme.spacing[1]}px 0px;

  ${({ variant }) =>
    variant === 'h1' &&
    css`
      height: ${({ theme }) => theme.spacing[10]}px;
      border-radius: ${({ theme }) => theme.spacing[5]}px;
      margin: ${({ theme }) => theme.spacing[1]}px 0px;
    `}

  ${({ variant }) =>
    variant === 'h2' &&
    css`
      height: ${({ theme }) => theme.spacing[8]}px;
      border-radius: ${({ theme }) => theme.spacing[4]}px;
      margin: ${({ theme }) => theme.spacing[1]}px 0px;
    `}

    ${({ variant }) =>
    variant === 'h3' &&
    css`
      height: ${({ theme }) => theme.spacing[6]}px;
      border-radius: ${({ theme }) => theme.spacing[3]}px;
      margin: ${({ theme }) => theme.spacing[1]}px 0px;
    `}

    ${({ variant }) =>
    variant === 'h4' &&
    css`
      height: ${({ theme }) => theme.spacing[5]}px;
      border-radius: ${({ theme }) => theme.spacing[2]}px;
      margin: ${({ theme }) => theme.spacing[1.5]}px 0px;
    `};
`;

export default TextPlaceholder;
