import { SVGProps } from 'react';

function SmileySad({
  height = 24,
  width = 24,
  color = '#000',
  strokeWidth = 2,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
      />
      <path
        d="M15.898 16.5a4.502 4.502 0 0 0-7.796 0"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.625 11.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25ZM15.375 11.25a1.125 1.125 0 1 0 0-2.25 1.125 1.125 0 0 0 0 2.25Z"
        fill={color}
      />
    </svg>
  );
}

SmileySad.displayName = 'SmileySad';

export default SmileySad;
