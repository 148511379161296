import { SVGProps } from 'react';

function MinusCircle({
  height = 24,
  width = 24,
  color = '#000',
  strokeWidth = 2,
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit={10}
      />
      <path
        d="M8.25 12h7.5"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

MinusCircle.displayName = 'MinusCircle';

export default MinusCircle;
