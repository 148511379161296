import * as S from './IconButton.styles';

import CircleIcon from '../CircleIcon';
import { IconButtonProps } from './types';

function IconButton({
  size,
  color,
  colorVariant,
  icon,
  ...otherProps
}: IconButtonProps) {
  return (
    <S.Button {...otherProps} size={size}>
      <CircleIcon
        size={size}
        backgroundColor={color}
        backgroundColorVariant={colorVariant}
        iconColor={color === 'light' ? 'light' : 'dark'}
        iconColorVariant="primary"
        icon={icon}
      />
    </S.Button>
  );
}

export default IconButton;
