import styled, { css } from 'styled-components';

import { TextProps } from './types';

const swapTheme = {
  light: 'dark' as const,
  dark: 'light' as const,
  danger: 'danger' as const,
  success: 'success' as const,
  info: 'info' as const,
};

export const Text = styled.p<TextProps>`
  color: ${({ theme, color = 'dark', colorVariant = 'primary' }) =>
    theme.colors.content[swapTheme[color]][colorVariant]};
  font-family: ${({ theme }) => theme.fontFamily.sans};
  font-weight: ${({ strong }) => (strong ? 500 : 400)};
  font-size: ${({ theme, small }) =>
    small ? theme.spacing[3] : theme.spacing[4]}px;
  line-height: ${({ theme, small }) =>
    small ? theme.spacing[4] : theme.spacing[6]}px;
  text-align: ${({ align }) => align || 'left'};
  text-decoration: ${({ underline, lineThrough }) =>
    underline ? 'underline' : lineThrough ? 'line-through' : 'none'};

  ${({ variant }) =>
    variant === 'h1' &&
    css`
      font-weight: 600;
      font-size: ${({ theme }) => theme.spacing[10]}px;
      line-height: ${({ theme }) => theme.spacing[12]}px;
    `}
  ${({ variant }) =>
    variant === 'h2' &&
    css`
      font-weight: 600;
      font-size: ${({ theme }) => theme.spacing[8]}px;
      line-height: ${({ theme }) => theme.spacing[10]}px;
    `}
    ${({ variant }) =>
    variant === 'h3' &&
    css<TextProps>`
      font-weight: ${({ strong }) => (strong ? 500 : 400)};
      font-size: ${({ theme }) => theme.spacing[6]}px;
      line-height: ${({ theme }) => theme.spacing[8]}px;
    `}
    ${({ variant }) =>
    variant === 'h4' &&
    css<TextProps>`
      font-weight: ${({ strong }) => (strong ? 500 : 400)};
      font-size: ${({ theme }) => theme.spacing[5]}px;
      line-height: ${({ theme }) => theme.spacing[8]}px;
    `};
`;
