import styled, { css, Spacing } from 'styled-components';

const exists = (value?: any) => typeof value !== 'undefined';

type FlexContainerProps = Pick<
  React.CSSProperties,
  | 'justifyContent'
  | 'alignItems'
  | 'flexDirection'
  | 'flexWrap'
  | 'flexFlow'
  | 'alignContent'
  | 'gap'
  | 'flex'
> & {
  /**
   * Occupy the full width of the container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Occupy the full height of the container
   * @default false
   */
  fullHeight?: boolean;
  padding?: Spacing;
  paddingVertical?: Spacing;
  paddingHorizontal?: Spacing;
  paddingLeft?: Spacing;
  paddingRight?: Spacing;
  paddingTop?: Spacing;
  paddingBottom?: Spacing;
};

const FlexContainer = styled.div<FlexContainerProps>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent ?? ''};
  align-items: ${({ alignItems }) => alignItems ?? ''};
  flex-direction: ${({ flexDirection }) => flexDirection ?? ''};
  flex-wrap: ${({ flexWrap }) => flexWrap ?? ''};
  flex-flow: ${({ flexFlow }) => flexFlow ?? ''};
  align-content: ${({ alignContent }) => alignContent ?? ''};
  gap: ${({ gap }) => gap ?? ''};
  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
  ${({ flex }) =>
    flex &&
    css`
      flex: ${flex};
    `}
  padding: ${({
    theme,
    padding = 0,
    paddingLeft,
    paddingRight,
    paddingHorizontal,
    paddingVertical,
    paddingBottom,
    paddingTop,
  }) => {
    const paddingL =
      exists(paddingLeft) || exists(paddingHorizontal)
        ? paddingLeft ?? paddingHorizontal ?? 0
        : padding;
    const paddingR =
      exists(paddingRight) || exists(paddingHorizontal)
        ? paddingRight ?? paddingHorizontal ?? 0
        : padding;
    const paddingT =
      exists(paddingTop) || exists(paddingVertical)
        ? paddingTop ?? paddingVertical ?? 0
        : padding;
    const paddingB =
      exists(paddingBottom) || exists(paddingVertical)
        ? paddingBottom ?? paddingVertical ?? 0
        : padding;

    return `${theme.spacing[paddingT]}px ${theme.spacing[paddingR]}px ${theme.spacing[paddingB]}px ${theme.spacing[paddingL]}px`;
  }};
`;

export default FlexContainer;
