import styled, { css } from 'styled-components';

import FlexContainer from '../FlexContainer';

interface IconContainerProps {
  variant: 'success' | 'error' | 'processing' | 'securing';
}

export const Container = styled(FlexContainer)`
  position: relative;
`;

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.spacing[14]}px;
  height: ${({ theme }) => theme.spacing[14]}px;
  border-radius: ${({ theme }) => theme.spacing[7]}px;

  ${({ theme, variant }) =>
    variant === 'success' &&
    css`
      background-color: ${theme.colors.primary.yellow};
    `}

  ${({ theme, variant }) =>
    variant === 'error' &&
    css`
      background-color: ${theme.colors.content.danger.primary};
    `}

  ${({ theme, variant }) =>
    variant === 'processing' &&
    css`
      background-color: ${theme.colors.primary.blue};
    `}

  ${({ theme, variant }) =>
    variant === 'securing' &&
    css`
      background-color: ${theme.colors.primary.green};
    `}
`;

export const TopRightImage = styled.div`
  position: absolute;
  top: -100%;
  right: 0;
  z-index: -1;
`;

export const BottomLeftImage = styled.div`
  position: absolute;
  bottom: -100%;
  left: 0;
  z-index: -1;
`;
