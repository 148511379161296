import * as S from './Text.styles';

import TextPlaceholder from './Text.placeholder';
import { TextProps } from './types';

function Text({ variant, ...props }: TextProps) {
  return <S.Text {...props} as={variant} variant={variant} />;
}

Text.Placeholder = TextPlaceholder;

export default Text;
