import { useIntl } from 'react-intl';

import * as S from './Header.styles';

import { Text } from '@ui';
import { Sammy } from '@ui/Icon/Outline';
import { useAnalytics } from '@src/hooks';

function Header() {
  const intl = useIntl();
  const { captureEvent } = useAnalytics();

  return (
    <S.Container fullWidth justifyContent="space-between" alignItems="center">
      <Sammy height={44} width={44} color="white" />
      <S.ButtonLink
        href="https://www.usesammy.com/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => captureEvent('Explore Sammy Clicked')}
      >
        <Text color="light">
          {intl.formatMessage({
            defaultMessage: 'Explore Sammy',
            id: 'gSb19O',
          })}
        </Text>
      </S.ButtonLink>
    </S.Container>
  );
}

export default Header;
