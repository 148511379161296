import * as S from './Result.styles';

import { Icon, Spacer, Text } from '@ui';
import { IconName } from '@src/components/ui/Icon/types';
interface ResultProps {
  title: string;
  message: string;
  variant: 'success' | 'error' | 'processing' | 'securing';
  topRightImage?: React.ReactNode;
  bottomLeftImage?: React.ReactNode;
}

function Result({
  title,
  message,
  variant,
  topRightImage,
  bottomLeftImage,
}: ResultProps) {
  let iconName: IconName | undefined;
  if (variant === 'success') {
    iconName = 'Check';
  }

  if (variant === 'error') {
    iconName = 'SmileySad';
  }

  if (variant === 'processing') {
    iconName = 'ArrowClockwise';
  }

  if (variant === 'securing') {
    iconName = 'ShieldChevron';
  }

  return (
    <S.Container
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {iconName && (
        <S.IconContainer variant={variant}>
          <Icon name={iconName} size={32} />
        </S.IconContainer>
      )}

      <Spacer size={6} />
      <Text variant="h3" strong color="light" align="center">
        {title}
      </Text>
      <Spacer size={2} />
      <Text color="light" align="center">
        {message}
      </Text>

      {topRightImage && <S.TopRightImage>{topRightImage}</S.TopRightImage>}
      {bottomLeftImage && (
        <S.BottomLeftImage>{bottomLeftImage}</S.BottomLeftImage>
      )}
    </S.Container>
  );
}

export default Result;
