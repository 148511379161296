import * as S from './CircleIcon.styles';

import { CircleIconProps } from './types';

interface CircleIconPlaceholderProps {
  size: CircleIconProps['size'];
}

const CircleIconPlaceholder = ({ size }: CircleIconPlaceholderProps) => (
  <S.Placeholder $size={size} />
);

export default CircleIconPlaceholder;
