import styled from 'styled-components';

import { BackdropProps } from './types';

export const Wrapper = styled.div<BackdropProps>`
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: -1;

  &.backdrop-enter-done {
    opacity: 1;
    z-index: ${({ theme }) => theme.zIndex[50]};
  }

  &.backdrop-exit-done {
    z-index: -1;
    opacity: 0;
  }
  &.backdrop-exit-active {
    z-index: ${({ theme }) => theme.zIndex[50]};
  }
`;

export const Overlay = styled.div<BackdropProps>`
  background-color: ${({ theme }) => theme.colors.content.light.primary};
  @media (min-width: 576px) {
    background-color: ${({ theme }) => theme.colors.content.light.tertiary};
  }
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  transition: opacity 300ms;

  &.overlay-enter {
    opacity: 0;
  }
  &.overlay-enter-active {
    opacity: 1;
  }
  &.overlay-enter-done {
    opacity: 1;
  }
  &.overlay-exit {
    transition: opacity 300ms;
    opacity: 1;
  }
  &.overlay-exit-active {
    transition: opacity 300ms;
    opacity: 0;
  }
  &.overlay-exit-done {
    opacity: 0;
  }
`;
