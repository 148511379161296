import styled, { css } from 'styled-components';

import { ButtonProps } from './types';

type LabelProps = Pick<
  ButtonProps,
  'color' | 'colorVariant' | 'variant' | 'size'
>;

export const Button = styled.button<ButtonProps>`
  height: ${({ theme }) => theme.spacing[14]}px;
  border-radius: ${({ theme }) => theme.spacing[7]}px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fontFamily.sans};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:active {
    transform: ${({ disabled }) => !disabled && 'scale(0.99)'};
  }

  ${({ size, theme }) =>
    size === 'sm' &&
    css`
      height: ${theme.spacing[10]}px;
      width: auto;
      align-self: flex-start;
      border-radius: ${theme.spacing[5]}px;
      padding: 0 ${theme.spacing[4]}px;
    `}

  ${({ size, theme }) =>
    size === 'md' &&
    css`
      height: ${theme.spacing[14]}px;
      border-radius: 100px;
      width: 327px;
    `}

  ${({ size, theme }) =>
    size === 'lg' &&
    css`
      height: ${theme.spacing[18]}px;
      border-radius: ${theme.spacing[9]}px;
      width: 375px;
    `}

    ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  ${({ theme, color = 'color', colorVariant = 'primary', variant = 'solid' }) =>
    color === 'transparent' || variant === 'outlined'
      ? css`
          background-color: transparent;
        `
      : css`
          background-color: ${theme.colors.surface[color]?.[colorVariant]};
        `};

  ${({ theme, color = 'color', colorVariant = 'primary', variant = 'solid' }) =>
    variant === 'outlined' &&
    color !== 'transparent' &&
    (color === 'light' || color === 'dark') &&
    css`
      border: ${theme.spacing['px']}px
        ${theme.colors.border[color]?.[colorVariant]} solid;
    `};
`;

export const Label = styled.p<LabelProps>`
  text-align: center;
  font-size: ${({ theme, size = 'md' }) =>
    size === 'sm' ? theme.spacing[4] : theme.spacing[5]}px;
  font-family: ${({ theme }) => theme.fontFamily.sans};
  font-weight: ${({ size = 'md' }) => (size === 'sm' ? 400 : 500)};

  color: ${({
    theme,
    color = 'color',
    colorVariant = 'primary',
    variant = 'solid',
  }) =>
    color === 'transparent'
      ? theme.colors.content.light.secondary
      : variant === 'outlined'
      ? theme.colors.content.light.primary
      : theme.colors.content[color]?.[colorVariant]};
`;
