import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import * as S from './Modal.styles';

import Backdrop from '@ui/Backdrop';
import IconButton from '@ui/IconButton';

import Box from '../Box';
import Spacer from '../Spacer';
import Text from '../Text';
import type { ModalProps } from './types';

export default function Modal({
  children,
  open,
  container,
  CardProps,
  title,
  width,
  onClose,
  ...otherProps
}: ModalProps) {
  if (typeof document === 'undefined') {
    return null;
  }

  const containerElement = container && document.querySelector(container);
  const portalContainer = containerElement || document.body;

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (onClose) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <Backdrop open={open}>
      <CSSTransition
        in={open}
        timeout={300}
        classNames="modal"
        appear
        mountOnEnter
        unmountOnExit
      >
        <S.ModalCard {...otherProps} {...CardProps} padding={0} width={width}>
          <Box paddingHorizontal={6} paddingTop={6}>
            <S.CloseIconContainer>
              <IconButton
                onClick={handleClose}
                icon="X"
                size="sm"
                color="light"
                colorVariant="secondary"
              />
            </S.CloseIconContainer>
            <S.TitleContainer>
              <Text align="center">{title}</Text>
            </S.TitleContainer>
          </Box>
          <Spacer size={2} />
          <S.ModalContent paddingHorizontal={6} paddingBottom={6}>
            {children}
          </S.ModalContent>
        </S.ModalCard>
      </CSSTransition>
    </Backdrop>,
    portalContainer
  );
}
