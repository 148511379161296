import styled, { css } from 'styled-components';

import { SpacerProps } from './types';

const Spacer = styled.div<SpacerProps>`
  ${({ horizontal }) =>
    horizontal
      ? css<SpacerProps>`
          padding-left: ${({ size = 1, theme }) => theme.spacing[size] / 2}px;
          padding-right: ${({ size = 1, theme }) => theme.spacing[size] / 2}px;
        `
      : css<SpacerProps>`
          padding-top: ${({ size = 1, theme }) => theme.spacing[size] / 2}px;
          padding-bottom: ${({ size = 1, theme }) => theme.spacing[size] / 2}px;
        `};
`;

export default Spacer;
