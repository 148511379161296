import { SVGProps } from 'react';

function Amex({ height = 24, width = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_257_12139)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
          fill="#211E1E"
        />
        <rect x="1" y="4" width="22" height="16" rx="2" fill="#006FCF" />
        <rect
          x="0.5"
          y="3.5"
          width="23"
          height="17"
          rx="2.5"
          stroke="black"
          strokeOpacity="0.1"
        />
        <path
          d="M4.25716 9.5L2 14.495H4.70214L5.03713 13.6986H5.80283L6.13782 14.495H9.11211V13.8872L9.37714 14.495H10.9157L11.1807 13.8743V14.495H17.3664L18.1186 13.7193L18.8229 14.495L22 14.5014L19.7357 12.0115L22 9.5H18.8722L18.14 10.2614L17.4579 9.5H10.7286L10.1508 10.7893L9.55936 9.5H6.86286V10.0872L6.5629 9.5C6.5629 9.5 4.25716 9.5 4.25716 9.5ZM4.78001 10.2093H6.09716L7.59433 13.5965V10.2093H9.03722L10.1936 12.6379L11.2594 10.2093H12.695V13.7936H11.8215L11.8143 10.985L10.5407 13.7936H9.75929L8.47857 10.985V13.7936H6.68143L6.34072 12.99H4.50002L4.16002 13.7929H3.19713L4.78001 10.2093ZM13.4943 10.2093H17.0464L18.1329 11.3828L19.2543 10.2093H20.3407L18.69 12.0108L20.3407 13.7914H19.205L18.1186 12.6043L16.9914 13.7914H13.4943V10.2093ZM5.42076 10.8157L4.81432 12.2472H6.0265L5.42076 10.8157ZM14.3715 10.9514V11.6057H16.3093V12.335H14.3715V13.0493H16.545L17.555 11.9972L16.5879 10.9508H14.3715V10.9514Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_257_12139">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Amex.displayName = 'Amex';

export default Amex;
