import { useEffect, useState } from 'react';
import posthog from 'posthog-js';

export enum FeatureFlag {
  STRIPE_CC = 'stripe-cc',
  STRIPE_ACH = 'stripe-ach',
}

const useFeatureFlag = (name: FeatureFlag) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    posthog.onFeatureFlags((_, flags) => {
      setEnabled(Boolean(flags[name]));
    });
  }, [name]);

  return enabled;
};

export default useFeatureFlag;
