interface LoadingProps {
  size?: 'sm' | 'lg';
  color?: string;
}

import * as S from './LoadingIcon.styles';

function LoadingIcon({ size = 'lg', color = '#000' }: LoadingProps) {
  return (
    <div>
      <S.SVG fill="none" size={size}>
        <path
          d="M53.14 28c1.856 0 3.38 1.51 3.159 3.352A28.001 28.001 0 0 1 8.7 47.799 28 28 0 0 1 25.148.201C26.99-.02 28.5 1.504 28.5 3.36c0 1.856-1.514 3.333-3.346 3.625a21.28 21.28 0 1 0 24.361 24.361C49.807 29.514 51.285 28 53.14 28Z"
          fill={color}
        />
      </S.SVG>
    </div>
  );
}

export default LoadingIcon;
