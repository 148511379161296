import { SVGProps } from 'react';

function Visa({ height = 24, width = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_257_12137)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
          fill="#211E1E"
        />
        <rect x="1" y="4" width="22" height="16" rx="2" fill="#244193" />
        <rect
          x="0.5"
          y="3.5"
          width="23"
          height="17"
          rx="2.5"
          stroke="black"
          strokeOpacity="0.1"
        />
        <g clipPath="url(#clip1_257_12137)">
          <path
            d="M12.3092 10.8582C12.2989 11.668 13.0308 12.1198 13.5821 12.3885C14.1485 12.6641 14.3388 12.8408 14.3366 13.0874C14.3323 13.4646 13.8847 13.6311 13.4659 13.6376C12.7351 13.6489 12.3102 13.4403 11.9724 13.2825L11.7092 14.5143C12.0481 14.6705 12.6756 14.8066 13.3264 14.8126C14.8539 14.8126 15.8533 14.0586 15.8587 12.8895C15.8647 11.4058 13.8064 11.3236 13.8205 10.6605C13.8253 10.4594 14.0172 10.2448 14.4377 10.1902C14.6458 10.1626 15.2203 10.1416 15.8717 10.4415L16.1273 9.24971C15.777 9.12216 15.3268 9.00003 14.7663 9.00003C13.3286 9.00003 12.3173 9.76433 12.3092 10.8582ZM18.5839 9.10268C18.305 9.10268 18.0699 9.26539 17.965 9.51507L15.783 14.7251H17.3094L17.6132 13.8856H19.4784L19.6546 14.7251H21L19.826 9.10268H18.5839ZM18.7975 10.6215L19.238 12.7328H18.0315L18.7975 10.6215ZM10.4585 9.10275L9.25528 14.725H10.7098L11.9125 9.10261H10.4585V9.10275ZM8.3067 9.10261L6.79273 12.9295L6.1803 9.67566C6.10845 9.31243 5.82466 9.10268 5.50952 9.10268H3.03466L3 9.26595C3.50808 9.3762 4.08534 9.55402 4.43508 9.74429C4.64911 9.86051 4.71014 9.96211 4.78045 10.2383L5.9404 14.7251H7.4775L9.83409 9.10268H8.3067"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_257_12137">
          <rect width="24" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_257_12137">
          <rect
            width="18"
            height="5.83594"
            fill="white"
            transform="translate(3 9)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

Visa.displayName = 'Visa';

export default Visa;
