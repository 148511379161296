import React, { useEffect } from 'react';
import { Spacing } from 'styled-components';

import { Modal as ModalUI } from '@ui';
import { useModal } from '@src/hooks';

interface ModalProps {
  component: React.FC<any>;
  zIndex?: 40 | 50;
  closable?: boolean;
  title?: string;
  width?: Spacing;
}

export interface ModalChildrenProps {
  onClose?: () => void;
}

function Modal({
  component: Component,
  zIndex = 40,
  closable = true,
  title,
  width,
}: ModalProps) {
  const { props, isOpen, close } = useModal(Component, zIndex);

  // Closes modal when unmounted. Works for unmounting when navigation.
  useEffect(() => close, []);

  return (
    <ModalUI
      open={isOpen}
      onClose={closable ? close : undefined}
      title={title}
      width={width}
    >
      <Component {...props} onClose={close} />
    </ModalUI>
  );
}

export default Modal;
