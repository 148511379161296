import styled, { css } from 'styled-components';

import { CardProps } from './types';

const Card = styled.div<CardProps>`
  background-color: ${({
    theme,
    backgroundColor = 'light',
    backgroundColorVariant = 'primary',
  }) => theme.colors.surface[backgroundColor][backgroundColorVariant]};
  border-radius: ${({ borderRadius = 10, theme }) =>
    theme.spacing[borderRadius]}px;
  padding: ${({
    theme,
    padding = 6,
    paddingLeft = 0,
    paddingRight = 0,
    paddingHorizontal = 0,
    paddingVertical = 0,
    paddingBottom = 0,
    paddingTop = 0,
  }) => {
    const isCustomPadding =
      paddingLeft ||
      paddingHorizontal ||
      paddingRight ||
      paddingVertical ||
      paddingBottom ||
      paddingTop;
    const paddingL = isCustomPadding
      ? paddingLeft || paddingHorizontal || padding
      : padding;
    const paddingR = isCustomPadding
      ? paddingRight || paddingHorizontal || padding
      : padding;
    const paddingT = isCustomPadding
      ? paddingTop || paddingVertical || padding
      : padding;
    const paddingB = isCustomPadding
      ? paddingBottom || paddingVertical || padding
      : padding;
    return `${theme.spacing[paddingT]}px ${theme.spacing[paddingR]}px ${theme.spacing[paddingB]}px ${theme.spacing[paddingL]}px`;
  }};

  ${({ borderBottomLeftRadius, theme }) =>
    typeof borderBottomLeftRadius !== 'undefined' &&
    css`
      border-bottom-left-radius: ${theme.spacing[borderBottomLeftRadius]}px;
    `}

  ${({ borderBottomRightRadius, theme }) =>
    typeof borderBottomRightRadius !== 'undefined' &&
    css`
      border-bottom-right-radius: ${theme.spacing[borderBottomRightRadius]}px;
    `}

  ${({ borderTopLeftRadius, theme }) =>
    typeof borderTopLeftRadius !== 'undefined' &&
    css`
      border-top-left-radius: ${theme.spacing[borderTopLeftRadius]}px;
    `}

  ${({ borderTopRightRadius, theme }) =>
    typeof borderTopRightRadius !== 'undefined' &&
    css`
      border-top-right-radius: ${theme.spacing[borderTopRightRadius]}px;
    `}

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      height: 100%;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};
`;

export default Card;
