import styled from 'styled-components';

import { DividerProps } from './types';

const Divider = styled.div<DividerProps>`
  height: ${({ height = 1 }) => height}px;
  background-color: ${({ color = 'light', colorVariant = 'tertiary', theme }) =>
    theme.colors.border[color][colorVariant]};
  width: 100%;
`;

export default Divider;
