import React from 'react';

import { Spacer, Text } from '@ui';
import { FlexContainer } from '@shared';

interface ListItemProps {
  label: string;
  value: React.ReactNode;
  subValue?: string;
  strong?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
}

function ListItem({
  label,
  value,
  subValue,
  strong,
  startAdornment,
  endAdornment,
}: ListItemProps) {
  return (
    <FlexContainer alignItems="center" paddingVertical={5}>
      {startAdornment && (
        <>
          {startAdornment}
          <Spacer size={6} horizontal />
        </>
      )}
      <FlexContainer flexDirection="column" flex={1}>
        {label && (
          <>
            <Text small colorVariant="tertiary">
              {label}
            </Text>
            <Spacer size={1} />
          </>
        )}

        {typeof value === 'string' ? (
          <Text strong={strong}>{value}</Text>
        ) : (
          value
        )}

        {subValue && (
          <Text color="dark" colorVariant="tertiary">
            {subValue}
          </Text>
        )}
      </FlexContainer>
      {endAdornment && (
        <>
          <Spacer size={6} horizontal />
          <FlexContainer alignItems="center">{endAdornment}</FlexContainer>
        </>
      )}
    </FlexContainer>
  );
}

export default ListItem;
