import { SVGProps } from 'react';

function Discover({ height = 24, width = 24 }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_257_12140)">
        <path
          d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
          fill="#211E1E"
        />
        <rect x="1" y="4" width="22" height="16" rx="2" fill="white" />
        <rect
          x="0.5"
          y="3.5"
          width="23"
          height="17"
          rx="2.5"
          stroke="black"
          strokeOpacity="0.1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.47797 12.6842C4.2999 12.8365 4.07322 12.9031 3.71072 12.9031H3.5603V11.0861H3.71072C4.07322 11.0861 4.29121 11.1482 4.47797 11.308C4.67076 11.4732 4.78662 11.728 4.78662 11.9915C4.78662 12.2564 4.67076 12.5202 4.47797 12.6842ZM3.82301 10.6207H3V13.3687H3.81799C4.2533 13.3687 4.56752 13.2696 4.84293 13.0508C5.17008 12.7918 5.36421 12.4006 5.36421 11.9954C5.36421 11.1841 4.73087 10.6207 3.82301 10.6207Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.62256 10.6207H6.18063V13.3687H5.62256V10.6207Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.55117 11.6739C7.21432 11.5557 7.11586 11.4769 7.11586 11.3284C7.11586 11.1558 7.29182 11.0248 7.53378 11.0248C7.70148 11.0248 7.83963 11.0891 7.98682 11.246L8.27818 10.8803C8.03744 10.6775 7.74931 10.5749 7.43521 10.5749C6.92698 10.5749 6.53961 10.9132 6.53961 11.3606C6.53961 11.7405 6.71969 11.9332 7.24521 12.1162C7.46487 12.1892 7.57648 12.2392 7.63302 12.273C7.74497 12.3422 7.80072 12.4415 7.80072 12.5562C7.80072 12.7797 7.61562 12.944 7.36541 12.944C7.09903 12.944 6.88349 12.8164 6.75448 12.5768L6.39343 12.9114C6.65156 13.2733 6.96099 13.4351 7.38726 13.4351C7.96864 13.4351 8.37831 13.0626 8.37831 12.5321C8.37831 12.0949 8.1883 11.8968 7.55117 11.6739Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.5542 11.9954C8.5542 12.8035 9.21709 13.4297 10.0704 13.4297C10.311 13.4297 10.5173 13.3843 10.7713 13.2696V12.638C10.5469 12.8539 10.3499 12.939 10.095 12.939C9.53108 12.939 9.13168 12.5486 9.13168 11.9916C9.13168 11.4652 9.54402 11.0482 10.0704 11.0482C10.336 11.0482 10.539 11.1383 10.7713 11.3573V10.7265C10.5259 10.6078 10.3245 10.5586 10.0819 10.5586C9.23348 10.5586 8.5542 11.1971 8.5542 11.9954Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3086 12.4656L14.5428 10.6207H13.9323L15.1496 13.438H15.4499L16.691 10.6207H16.0852L15.3086 12.4656Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9451 13.3687H18.5332V12.9031H17.5054V12.1613H18.4942V11.6958H17.5054V11.0861H18.5332V10.6207H16.9451V13.3687Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6271 11.8843H19.4645V11.0531H19.6362C19.9861 11.0531 20.1735 11.1937 20.1735 11.4614C20.1735 11.7364 19.9861 11.8843 19.6271 11.8843ZM20.7502 11.4315C20.7502 10.9169 20.3814 10.6207 19.7348 10.6207H18.903V13.3687H19.4645V12.2638H19.5373L20.311 13.3687H21L20.0965 12.2105C20.5188 12.1279 20.7502 11.852 20.7502 11.4315Z"
          fill="#201D1C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0112 12.0018C14.0112 12.793 13.3413 13.4342 12.5141 13.4342C11.6872 13.4342 11.0172 12.793 11.0172 12.0018C11.0172 11.2105 11.6872 10.5692 12.5141 10.5692C13.3413 10.5692 14.0112 11.2105 14.0112 12.0018Z"
          fill="url(#paint0_linear_257_12140)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0112 12.0018C14.0112 12.793 13.3413 13.4342 12.5141 13.4342C11.6872 13.4342 11.0172 12.793 11.0172 12.0018C11.0172 11.2105 11.6872 10.5692 12.5141 10.5692C13.3413 10.5692 14.0112 11.2105 14.0112 12.0018Z"
          fill="url(#paint1_radial_257_12140)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_257_12140"
          x1="11.5957"
          y1="10.8399"
          x2="13.459"
          y2="13.0899"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6772F" />
          <stop offset="1" stopColor="#EA9D2C" />
        </linearGradient>
        <radialGradient
          id="paint1_radial_257_12140"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.8438 12.5977) rotate(-128.1) scale(1.96569 2.05413)"
        >
          <stop stopColor="#EA9D2C" stopOpacity="0" />
          <stop offset="0.328125" stopColor="#DF7624" stopOpacity="0" />
          <stop offset="0.760188" stopColor="#BF4B23" stopOpacity="0.75" />
          <stop offset="1" stopColor="#7D3017" />
        </radialGradient>
        <clipPath id="clip0_257_12140">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

Discover.displayName = 'Discover';

export default Discover;
