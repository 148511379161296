import * as S from './CircleIcon.styles';

import Icon from '../Icon';
import CircleIconPlaceholder from './CircleIcon.placeholder';
import { CircleIconProps } from './types';

function CircleIcon({
  icon,
  iconColor,
  iconColorVariant,
  size,
  ...otherProps
}: CircleIconProps) {
  const iconSizeMap: Partial<Record<CircleIconProps['size'], number>> = {
    xs: 16,
    xl: 32,
  };

  return (
    <S.Container size={size} {...otherProps}>
      <Icon
        name={icon}
        color={iconColor}
        colorVariant={iconColorVariant}
        size={iconSizeMap[size]}
      />
    </S.Container>
  );
}

CircleIcon.Placeholder = CircleIconPlaceholder;

export default CircleIcon;
