import Dinero, { Currency } from 'dinero.js';

interface Options {
  format?: string;
  currency?: Currency;
}

function useCurrency(cents: number, options?: Options) {
  const { currency = 'USD', format = '$0,0.00' } = options || {};
  const amount = Dinero({
    amount: cents,
    currency,
  }).setLocale('en-US');

  return amount.toFormat(format);
}

export default useCurrency;
