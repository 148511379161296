import Dinero, { Currency } from 'dinero.js';

interface Options {
  format?: string;
  currency?: Currency;
  locale?: string;
}

function formatAmount(cents: number, options?: Options) {
  if (typeof cents !== 'number') {
    return '';
  }

  const { currency = 'USD', format = '$0,0.00', locale = 'en-US' } =
    options || {};

  const amount = Dinero({
    amount: cents,
    currency,
  }).setLocale(locale);

  return `${amount.toFormat(format)} ${currency}`;
}

export default formatAmount;
