import styled from 'styled-components';

import Box from '../Box';
import Card from '../Card';
import { ModalProps } from './types';

type ModalCardProps = Pick<ModalProps, 'width'>;

export const ModalCard = styled(Card)<ModalCardProps>`
  width: ${({ width, theme }) =>
    width ? `${theme.spacing[width]}px` : 'auto'};
  position: relative;
  max-height: 100dvh;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  &.modal-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  &.modal-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  &.modal-exit {
    opacity: 1;
  }
  &.modal-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`;

export const ModalContent = styled(Box)`
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  width: 100%;
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.spacing[6]}px;
  left: ${({ theme }) => theme.spacing[6]}px;
`;

export const TitleContainer = styled.div`
  flex: 1;
  height: ${({ theme }) => theme.spacing[10]}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
