import { SVGProps } from 'react';

function Sammy({
  height = 80,
  width = 80,
  color = '#000',
  ...rest
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 80 80"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M40 0C17.905 0 0 17.905 0 40s17.905 40 40 40 40-17.905 40-40S62.084 0 40 0Zm16.737 54.197c-1.28 1.926-3.206 3.411-5.767 4.487-1.68.696-3.718 1.219-6.125 1.485-.625.072-1.157.41-1.536.912A4.153 4.153 0 0 1 40 62.72a4.248 4.248 0 0 1-3.4-1.742c-.36-.502-.882-.85-1.496-.942-1.7-.236-3.247-.605-4.63-1.137-2.776-1.066-4.907-2.561-6.402-4.692-1.496-2.13-2.13-4.701-2.13-7.682v-.43h8.962v1.065c0 1.28.215 2.13.85 2.991.635.85 1.496 1.28 2.991 1.71 1.496.43 3.411.646 6.187.646 2.346 0 4.272-.215 5.552-.43 1.28-.215 2.13-.645 2.56-1.28.431-.636.636-1.281.636-2.131 0-1.28-.43-2.13-1.28-2.776-.85-.635-2.131-1.065-4.272-1.28l-11.319-1.711c-2.345-.43-4.486-1.066-6.187-1.926a8.97 8.97 0 0 1-3.841-3.626c-.85-1.495-1.28-3.411-1.28-5.552 0-1.495.215-2.99.85-4.486.635-1.496 1.71-2.776 3.206-3.842 1.495-1.28 3.206-2.13 5.552-2.776a29.033 29.033 0 0 1 3.995-.788c.625-.082 1.167-.43 1.546-.943a4.152 4.152 0 0 1 3.34-1.67c1.372 0 2.622.687 3.38 1.732.379.522.922.901 1.557 1.004 1.803.297 3.39.737 4.743 1.3 2.56 1.066 4.691 2.776 5.982 4.907 1.29 2.13 1.925 4.487 1.925 7.262v.43H48.83v-.85c0-1.28-.215-2.345-.85-2.99-.43-.85-1.496-1.28-2.991-1.711-1.496-.43-3.411-.645-6.188-.645-2.13 0-3.84.215-5.121.43-1.28.215-2.13.645-2.56 1.28-.431.635-.851 1.28-.851 2.13 0 .851.215 1.281.43 1.927.215.43.85.85 1.496 1.065.635.215 1.495.43 2.776.645l11.318 1.71c2.991.431 5.337 1.281 7.048 2.347 1.71 1.065 2.99 2.345 3.841 3.84a9.967 9.967 0 0 1 1.28 4.907c.215 2.13-.43 4.272-1.71 6.187h-.01Z"
        fill={color}
      />
    </svg>
  );
}

export default Sammy;
