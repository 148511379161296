import { useCallback } from 'react';
import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

import { Events } from '@src/lib/analytics/events';

type AnalyticsUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
};

function useAnalytics() {
  const identifyUser = useCallback((user: AnalyticsUser) => {
    const userAttributes = {
      name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
      email: user.email,
    };

    posthog?.identify(user.id, userAttributes);

    Sentry.setUser({
      id: user.id,
      email: user.email,
      name: `${user.firstName ?? ''} ${user.lastName ?? ''}`,
    });
  }, []);

  const captureEvent = useCallback(
    <T extends keyof Events>(
      ...args: Events[T] extends undefined
        ? [event: T, properties?: Events[T]]
        : [event: T, properties: Events[T]]
    ) => {
      const [event, properties] = args;

      posthog?.capture(event, properties || {});
    },
    []
  );

  return {
    captureEvent,
    identifyUser,
  };
}

export default useAnalytics;
