import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

export const SVG = styled.svg<{ size: 'sm' | 'lg' }>`
  animation: ${rotate} 0.75s linear infinite;
  width: 56px;
  height: 56px;
  scale: ${({ size }) => (size === 'sm' ? 0.35 : 1)};
`;
