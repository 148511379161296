import { memo } from 'react';
import { Currency as CurrencyType } from 'dinero.js';

import Text from '@src/components/ui/Text';
import { TextProps } from '@src/components/ui/Text/types';
import { formatAmount } from '@src/utils/currency';

import Balance from '../Balance';

export interface CurrencyProps
  extends Omit<TextProps, 'style' | 'children' | 'variant'> {
  cents?: number;
  currency?: CurrencyType;
  variant?: 'balance' | TextProps['variant'];
}

function Currency({
  cents,
  currency = 'USD',
  variant,
  ...otherProps
}: CurrencyProps) {
  const showEmpty = cents == null;
  const formattedCurrency = formatAmount(cents ?? 0, {
    currency,
  });

  if (variant === 'balance') {
    return (
      <Balance {...otherProps}>{showEmpty ? '-' : formattedCurrency}</Balance>
    );
  }

  return (
    <Text variant={variant} {...otherProps}>
      {showEmpty ? '-' : formattedCurrency}
    </Text>
  );
}

const isSameAmount = (prevProps: CurrencyProps, nextProps: CurrencyProps) => {
  return (
    prevProps.cents === nextProps.cents &&
    prevProps.currency === nextProps.currency
  );
};

export default memo(Currency, isSameAmount);
